import React from 'react'
import { NavLink } from 'react-router-dom'

const SideFoter = () => {
  return (
    <>

<footer className='authorize'>
<div className="fotr">
© 2023 amttraders. All rights reserved.
</div>
</footer>

    </>
  )
}

export default SideFoter
import React from 'react'
import "../style/terms.css";
const Terms = () => {
  return (
    <div>
    <section className='terms_condition'>
<div className="container">
    <div className="card_wrap">
      <div className="main_heading">
        ourt terms <span className='and'>&</span> conditions
      </div>

      <div className="headings_bar">
        <h2>
          heading 01
        </h2>
        <div className="paragraph">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id vel beatae suscipit sit, inventore dolor obcaecati aliquid nemo ullam error architecto dignissimos incidunt culpa similique ipsa velit nesciunt alias necessitatibus!
        </div>
      </div>

      <div className="headings_bar">
        <h2>
          heading 01
        </h2>
        <div className="paragraph">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id vel beatae suscipit sit, inventore dolor obcaecati aliquid nemo ullam error architecto dignissimos incidunt culpa similique ipsa velit nesciunt alias necessitatibus!
        </div>
      </div>


      <div className="headings_bar">
        <h2>
          heading 01
        </h2>
        <div className="paragraph">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id vel beatae suscipit sit, inventore dolor obcaecati aliquid nemo ullam error architecto dignissimos incidunt culpa similique ipsa velit nesciunt alias necessitatibus!
        </div>
      </div>



      <div className="headings_bar">
        <h2>
          heading 01
        </h2>
        <div className="paragraph">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id vel beatae suscipit sit, inventore dolor obcaecati aliquid nemo ullam error architecto dignissimos incidunt culpa similique ipsa velit nesciunt alias necessitatibus!
        </div>
      </div>


      <div className="headings_bar">
        <h2>
          heading 01
        </h2>
        <div className="paragraph">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Id vel beatae suscipit sit, inventore dolor obcaecati aliquid nemo ullam error architecto dignissimos incidunt culpa similique ipsa velit nesciunt alias necessitatibus!
        </div>
      </div>
    </div>
</div>
</section>
    </div>
  )
}

export default Terms